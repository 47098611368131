import React, { useState } from "react";
import { Link, useHistory } from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel';

import UrlSlugs from "../../helpers/UrlSlugs";
import CommonProfileLogo from "../../components/common/CommonProfileLogo";
import LinkCopiedTooltip from "../../components/common/LinkCopiedTooltip";
import Utilities from "../../helpers/Utilities";
import ApiUrls from "../../helpers/ApiUrls";
import { viewMenuPricePopup } from "../../actions";
import TitlesLabels from "../../helpers/TitlesLabels";


const imgPath = process.env.REACT_APP_img_folder ?? '';


// 1 = company profile, 2 = company profile menu/ products list, 3 = upload pdf/ jpg, 4 = external link	
export const handleClickAds = (detail = {}, dispatch) => {
    if (Object.keys(detail).length > 0) {

        const adsFlag = parseInt(detail.ads_flag ?? 0);

        if (adsFlag === 1) {


        } else if (adsFlag === 2) {


        } else if (adsFlag === 3) {
            const fileUrl = detail.ads_file_link ?? '';
            if (Utilities.isEmpty(fileUrl)) {
                return false;
            }

            dispatch(viewMenuPricePopup({ file_url: fileUrl, new_tab: 0 }));


        } else if (adsFlag === 4) {
            if (!Utilities.isEmpty(detail.ads_file_link ?? '')) {
                window.open(detail.ads_file_link, '_blank');
            }

        }
    }
}



export const DailyGlanceCategoryLoader = () => {
    return (
        <ul className="ul-category-selection fs-20 font-gotham-bold">
            {
                [...Array(3 + (Math.floor(Math.random() * 3))).keys()].map((key) =>
                    <li key={key}> <div className=" bg-gray pt-2 pb-3 rounded" style={{ width: 100 }}> </div></li>
                )
            }

        </ul>
    )

}


export const DailyGlanceArticleLoader = () => {

    return (
        <section className="section-article global-preloader">
            <div className="row">
                <div className="col-lg-6 pr-lg-3">
                    <div className="py-5  bg-gray featured-image"><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></div>
                </div>
                <div className="col-lg-6 pl-lg-3">
                    <div className="mb-4">
                        <div className=" bg-gray py-3 rounded"></div>
                        <div className=" bg-gray py-3 mt-2 rounded col-8"></div>
                    </div>
                    <div>
                        <div className=" bg-gray py-2 rounded mt-2"></div>
                        <div className=" bg-gray py-2 rounded mt-2"></div>
                        <div className=" bg-gray py-2 rounded mt-2"></div>
                        <div className=" bg-gray py-2 rounded mt-2"></div>
                        <div className=" bg-gray py-2 rounded mt-2"></div>
                        <div className=" bg-gray py-2 rounded mt-2 col-6"></div>
                    </div>
                </div>
            </div>

        </section>

    )
}


export const DailyGlanceEventListLoader = () => {

    return (
        <ul className="ul-global-list ul-dglance-list global-preloader">
            {
                [...Array(3 + (Math.floor(Math.random() * 3))).keys()].map((key) =>
                    <li key={key}>
                        <div>
                            <div className="profile-image bg-gray logo-size-80 logo-size-md-75 mx-auto rounded border-0"></div>
                        </div>
                        <div className='line-height-1'>
                            <div className="bg-gray py-2 col-lg-6 mb-2"></div>
                            <div className="bg-gray py-2 col-lg-4"></div>
                        </div>
                        <div className="bg-gray py-2"></div>
                    </li>
                )
            }

        </ul>
    )
}



export const DailyGlanceFeaturedListLoader = () => {

    return (
        <ul className="ul-global-list ul-dglance-list global-preloader">
            {
                [...Array(3 + (Math.floor(Math.random() * 3))).keys()].map((key) =>
                    <li key={key}>
                        <div>
                            <div className="profile-image bg-gray logo-size-80 logo-size-md-75 mx-auto border-0"></div>
                        </div>
                        <div className='line-height-1'>
                            <div className="bg-gray py-2 col-lg-6 mb-2"></div>
                            <div className="bg-gray py-2 col-lg-4"></div>
                        </div>
                    </li>
                )
            }

        </ul>
    )
}



export const DailyGlanceArticleItemSlider = (props) => {

    const imagesSlider = props.imagesSlider ?? [];

    if (imagesSlider.length === 0) {
        return <></>

    } else {
        return (
            <Carousel controls={false}>
                {
                    imagesSlider.map(imageUrl =>
                        <Carousel.Item key={imageUrl}>
                            <img src={imageUrl} className="w-100 featured-image" />
                        </Carousel.Item>
                    )
                }
            </Carousel>
        )
    }


}



export const DailyGlanceFeaturedItemTemplate = (props) => {
    const history = useHistory();

    const detail = props.detail ?? {};
    const displayType = props.displayType ?? `list`;
    const description = detail.description ?? '';
    const dateSelected = props.dateSelected ?? '';

    const [showCodeTooltip, setShowCodeTooltip] = useState(false);

    const handleClickShareNotice = (info = {}) => {

        if (Object.keys(info).length > 0) {

            const urlShare = `${ApiUrls.deepLink}/${btoa(`notifi_news_feed_detail/${info.id ?? 0}/-3/${info.date ?? 0}`)}/${Utilities.removeSpecialChar(info.title ?? '')}`
            Utilities.copyContentDirect(urlShare);

            setShowCodeTooltip(true);
            setTimeout(() => {
                setShowCodeTooltip(false);
            }, 1000);
        }

    }

    const handleClickViewDetail = () => {
        if (!Utilities.isEmpty(dateSelected)) {
            history.push(`/${UrlSlugs.notificationDailyGlanceFeaturedDetail}/${btoa(detail.id ?? 0)}/${btoa(dateSelected ?? '')}`);
        }
    }


    if (Object.keys(detail).length === 0) {
        return <></>
    } else {


        if (displayType === `detail`) {

            const shareHtml = <div className="mt-4 pt-2 fs-gotham-book fs-15 fs-md-15 text-center d-flex align-items-center justify-content-center mb-5">
                <div className="d-flex align-items-center justify-content-center cursor-pointer  position-relative" onClick={() => handleClickShareNotice(detail)}>
                    <img src={`${imgPath}/icons/black/circle-black-connected-dots.svg`} className="width-60" />
                    <span className="ml-2 pl-1">share</span>
                    <LinkCopiedTooltip boolShow={showCodeTooltip} text={TitlesLabels.general.linkCopied} extraClass={`ml-4`} />
                </div>
            </div>

            return (

                <div className="row pb-5">
                    <div className="col-lg-6 pr-lg-3">
                        {
                            (detail.image_gallery ?? []).length > 0 &&
                            <div className="pb-5"><DailyGlanceArticleItemSlider imagesSlider={detail.image_gallery ?? []} /></div>
                        }

                    </div>
                    <div className="col-lg-6 pl-lg-3">

                        <div className="d-flex align-items-center text-lowercase">
                            <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60 m`} profileDetail={{ img: detail.logo_url ?? '', name: detail.title ?? '' }} />
                            <div className="font-gotham-bold fs-20 fs-md-20 ml-3  mt-3">{detail.name ?? ''}</div>
                        </div>

                        <div className="mt-3">
                            <div className="font-gotham-bold fs-20 fs-md-20 mb-3 line-height-1-2">{detail.title ?? ''}</div>
                            <div className="text-break-line" dangerouslySetInnerHTML={{ __html: description }}></div>
                        </div>

                        {shareHtml}
                    </div>
                </div>

            )

        } else {

            return (
                <li onClick={() => handleClickViewDetail()}>
                    <div>
                        <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60 mx-auto`} profileDetail={{ img: detail.logo_url ?? '', name: detail.title ?? '' }} />
                    </div>
                    <div className='line-height-1 font-gotham-bold'>
                        <div className=" fs-18 fs-md-16 mb-2 ellipsis-1">{detail.name ?? ''}</div>
                        <div className=" fs-17 fs-md-16"><div className=" ellipsis-1 col-6 px-0 line-height-1-2">{detail.title ?? ''}</div></div>
                    </div>
                </li>
            )

        }

    }


}


export const DailyGlanceEventItemTemplate = (props) => {
    const history = useHistory();

    const detail = props.detail ?? {};
    const displayType = props.displayType ?? `list`;
    const showSlider = props.showSlider ?? false;
    const description = detail.description ?? '';
    const dateSelected = props.dateSelected ?? '';

    const [showCodeTooltip, setShowCodeTooltip] = useState(false);

    const handleClickShareNotice = (info = {}) => {
        if (Object.keys(info).length > 0) {

            const urlShare = `${ApiUrls.deepLink}/${btoa(`notifi_news_feed_detail/${info.id ?? 0}/-1/${info.date ?? 0}`)}/${Utilities.removeSpecialChar(info.title ?? '')}`
            Utilities.copyContentDirect(urlShare);

            setShowCodeTooltip(true);
            setTimeout(() => {
                setShowCodeTooltip(false);
            }, 1000);
        }
    }


    const handleClickViewDetail = () => {
        if (!Utilities.isEmpty(dateSelected)) {
            history.push(`/${UrlSlugs.notificationDailyGlanceEventDetail}/${btoa(detail.id ?? 0)}/${btoa(dateSelected ?? '')}`);
        }

    }

    if (Object.keys(detail).length === 0) {
        return <></>
    } else {




        if (displayType === `detail`) {

            const shareHtml = <div className="mt-4 pt-2 fs-gotham-book fs-15 fs-md-15 text-center d-flex align-items-center justify-content-center mb-5">
                <div className="d-flex align-items-center justify-content-center cursor-pointer  position-relative" onClick={() => handleClickShareNotice(detail)}>
                    <img src={`${imgPath}/icons/black/circle-black-connected-dots.svg`} className="width-60" />
                    <span className="ml-2 pl-1">share</span>
                    <LinkCopiedTooltip boolShow={showCodeTooltip} text={TitlesLabels.general.linkCopied} extraClass={`ml-4`} />
                </div>
            </div>

            return (
                <div className="row pb-5">
                    <div className="col-lg-6 pr-lg-3">
                        {
                            showSlider ?
                                <div className="pb-5"><DailyGlanceArticleItemSlider imagesSlider={detail.image_gallery ?? []} /></div>
                                :
                                <img src={detail.image_banner ?? ''} className="w-100 featured-image" />
                        }


                        <div className="row mt-3 fs-14 fs-md-14 text-lowercase">
                            {
                                !Utilities.isEmpty(detail.date_title ?? '') &&
                                <>
                                    <div className="col-2 font-gotham-bold">date</div>
                                    <div className="col-9">{detail.date_title ?? ''}</div>
                                </>
                            }

                            {
                                !Utilities.isEmpty(detail.time_title ?? '') &&
                                <>
                                    <div className="col-2 font-gotham-bold">time</div>
                                    <div className="col-9">{detail.time_title ?? ''}</div>
                                </>
                            }

                            {
                                !Utilities.isEmpty(detail.location ?? '') &&
                                <>
                                    <div className="col-2 font-gotham-bold">location</div>
                                    <div className="col-9">{detail.location ?? ''}</div>
                                </>
                            }

                        </div>


                    </div>
                    <div className="col-lg-6 pl-lg-3">
                        <div className="font-gotham-bold fs-25 line-height-1-2 mb-3">{detail.title ?? ''}</div>
                        <div className="text-break-line">
                            <span dangerouslySetInnerHTML={{ __html: description }}></span>
                            {shareHtml}
                        </div>
                    </div>
                </div>
            )

        } else {

            return (
                <li onClick={() => handleClickViewDetail()}>
                    <div>
                        <CommonProfileLogo classAttr={`profile-image logo-size-80 logo-size-md-75 mx-auto rounded border-0`} profileDetail={{ img: detail.image_banner ?? '', name: detail.title ?? '' }} />
                    </div>
                    <div className='line-height-1'>
                        <div className="profile-name font-gotham-bold fs-18 fs-md-16 mb-2 ellipsis-1">{detail.title ?? ''}</div>
                        <div className="profile-label  color-theme-grey"><div className=" ellipsis-1 col-6 px-0">{detail.date_title ?? ''}</div></div>
                    </div>
                    <div className="text-right color-theme-grey  fs-16">{detail.time_title ?? ''}</div>
                </li>
            )
        }

    }


}



export const DailyGlanceArticleItemTemplate = (props) => {
    const detail = props.detail ?? {};
    const readMore = props.readMore ?? true;
    const showSlider = props.showSlider ?? false;
    const description = detail.description ?? '';
    const dateSelected = props.dateSelected ?? '';
    const flagType = parseInt(detail.type ?? 0);

    const [showCodeTooltip, setShowCodeTooltip] = useState(false);

    //notifi_news_feed_detail/43/g_6/2023-09-30
    const handleClickShareNotice = (info = {}) => {
        //console.log(info);
        if (Object.keys(info).length > 0) {

            let urlShare = `${ApiUrls.deepLink}/${btoa(`notifi_news_feed_detail/${info.id ?? 0}/${info.category_id ?? 0}/${info.date ?? 0}`)}/${Utilities.removeSpecialChar(info.title ?? '')}`;
            if (flagType == 4) { // featured
                urlShare = `${ApiUrls.deepLink}/${btoa(`notifi_news_feed_detail/${info.id ?? 0}/-3/${info.date ?? 0}`)}/${Utilities.removeSpecialChar(info.title ?? '')}`;
            }

            Utilities.copyContentDirect(urlShare);

            setShowCodeTooltip(true);
            setTimeout(() => {
                setShowCodeTooltip(false);
            }, 1000);
        }


    }

    if (Object.keys(detail).length === 0) {
        return <></>
    } else {

        let readMoreUrl = `/${UrlSlugs.notificationDailyGlanceArticle}/${btoa(detail.id)}/${btoa(detail.date ?? '')}`;
        if (flagType == 4) { // featured
            readMoreUrl = `/${UrlSlugs.notificationDailyGlanceFeaturedDetail}/${btoa(detail.id ?? 0)}/${btoa(detail.date ?? '')}`;
        }

        const shareHtml = <div className="mt-4 pt-2 fs-gotham-book fs-15 fs-md-15 text-center d-flex align-items-center justify-content-center mb-5">
            <div className="d-flex align-items-center justify-content-center cursor-pointer  position-relative" onClick={() => handleClickShareNotice(detail)}>
                <img src={`${imgPath}/icons/black/circle-black-connected-dots.svg`} className="width-60" />
                <span className="ml-2 pl-1">share</span>
                <LinkCopiedTooltip boolShow={showCodeTooltip} text={TitlesLabels.general.linkCopied} extraClass={`ml-4`} />
            </div>
        </div>

        let dateValue = detail.date ?? '';
        let timeValue = detail.time ?? '';
        let dateFormatted = '';

        if (!Utilities.isEmpty(dateValue)) {
            dateFormatted = dateValue;
            if (!Utilities.isEmpty(timeValue)) {
                dateFormatted = `${dateValue} ${timeValue}`;
            }
            dateFormatted = Utilities.getFormatedDate(dateFormatted, false);
        }


        return <>
            {
                parseInt(detail.type ?? 1) === 1 ?
                    <div className="row pb-5">
                        <div className="col-lg-6 pr-lg-3">
                            {
                                showSlider ?
                                    <div className="pb-5"><DailyGlanceArticleItemSlider imagesSlider={detail.image_gallery ?? []} /></div>
                                    :
                                    <img src={detail.image_banner ?? ''} className="w-100 featured-image" />
                            }

                        </div>
                        <div className="col-lg-6 pl-lg-3">
                            <div className="font-gotham-bold fs-25 line-height-1-2">{detail.title ?? ''}</div>
                            <div className="color-theme-grey mt-2 mb-3 text-lowercase">{dateFormatted}</div>
                            <div className="text-break-line">
                                <span dangerouslySetInnerHTML={{ __html: readMore ? description.split(" ", 100).join(" ") + '... ' : description }}></span>
                                {readMore && <Link to={readMoreUrl}>read more</Link>}
                                {!readMore && shareHtml}
                            </div>
                        </div>
                    </div>
                    :
                    <div className="text-center pt-4">
                        <div className="col-lg-8 mx-auto">
                            <CommonProfileLogo classAttr={`profile-image logo-size-160 logo-size-md-120 mx-auto`} profileDetail={{ logo: detail.image_banner ?? '', name: detail.title ?? '' }} />
                            <div className="font-gotham-bold fs-25 line-height-1-2 mt-3">{detail.title ?? ''}</div>
                            <div className="color-theme-grey mt-2 mb-3 text-lowercase">{dateFormatted}</div>
                            <div className="text-break-line">
                                <span dangerouslySetInnerHTML={{ __html: readMore ? description.split(" ", 100).join(" ") + '... ' : description }}></span>
                                {readMore && <Link to={readMoreUrl}>read more</Link>}
                                {!readMore && shareHtml}
                            </div>
                        </div>
                    </div>
            }
        </>
    }


}


export const RegisterProfileItemTemplate = (props) => {

    const row = props.detail ?? {};
    const dateSelected = props.dateSelected ?? '';


    if (Object.keys(row).length > 0) {

        let name = row.name ?? '';
        name = name.trim().toLowerCase();

        return (
            <li onClick={() => props.handleShowProfile(row)}>
                <CommonProfileLogo classAttr={`profile-image fs-14-important logo-size-60 logo-size-md-60 mx-auto`} profileDetail={{ ...row, name: name, logo_url: row.logo }} preventOnclick={true} />
                <div className='line-height-1 '>
                    <div className="profile-name  fs-20 fs-md-16 ellipsis-1 font-gotham-bold mb-1">{name}</div>
                    {(row.hasOwnProperty('category') && row.category !== '') && <div className="fs-18 fs-md-16 color-theme-grey">{row.category}</div>}
                    <div className="mt-1 fs-14 fs-md-14  color-theme-grey">{row.location ?? ''}</div>
                </div>
            </li>
        )
    } else {
        return <></>
    }
}